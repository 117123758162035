import React from "react"

import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    404!
  </Layout>
)

export default NotFoundPage
